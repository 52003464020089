<template>
  <div>
    <v-row align="start" justify="center">
      <v-col cols="12" lg="8">
        <v-toolbar tile flat color="transparent">
          <v-toolbar-title>
            NAT Traversal
          </v-toolbar-title>
          <v-spacer/>
          <v-btn fab small color="success" elevation="0" @click="Save"><v-icon>mdi-content-save</v-icon></v-btn>
        </v-toolbar>
        <div
            v-for="(option, index) of options"
            :key="index"
        >
          <v-card outlined class="pa-3 mb-4">
            <h4 :id="option.option" class="text-capitalize font-weight-light pl-3 mb-1">{{option.option}}</h4>
            <v-text-field
                :placeholder="option.option"
                filled
                rounded
                dense
                hide-details
                class="mb-2"
                v-model="config[option.option]"
            />
            <div class="pl-3">
              <div class="font-weight-light" style="white-space: break-spaces;">{{option.hint}}</div>
              <div><span class="blue-grey--text">Default:</span> {{option.default}}</div>
              <div><span class="blue-grey--text">Example:</span> {{option.example}}</div>
              <div v-if="option.validValues">
                <span class="blue-grey--text">Vaild Values:</span>
                <v-chip
                    v-for="value of option.validValues"
                    :key="value"
                    color="primary"
                    class="mx-1"
                    small
                >{{value}}</v-chip>
              </div>
              <div v-if="option.warning" class="mt-6">
                <v-card color="warning" dark>
                  <v-card-title><v-icon left>mdi-alert</v-icon>Warning</v-card-title>
                  <v-card-text>{{option.warning.text}}</v-card-text>
                </v-card>
              </div>
            </div>
          </v-card>
        </div>
      </v-col>
      <v-col cols="4">
        <v-card outlined
                :style="currentScroll > 10 ? 'position: fixed; top: 260px; width: 260px':''"
                class="hidden-md-and-down"
        >
          <v-card-title>Options</v-card-title>
          <v-card-text>
            <h3
                v-for="(item, index) of options"
                :key="index"
                @click="$vuetify.goTo(`#${item.option}`)"
                class="body-1"
                style="cursor: pointer"
            >{{index = index + 1}}: {{item.option}}</h3>
            <v-btn
                v-if="currentScroll > 10"
                color="success"
                style="margin-right: 60px"
                @click="Save"
                block
                class="my-2"
            >
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
            <v-btn
                v-if="currentScroll > 10"
                color="primary"
                @click="$vuetify.goTo(0)"
                block
            >
              <v-icon>mdi-arrow-up-bold</v-icon>
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  name: "natTraversal",
  props: {
    domain:{
      type: String,
      required: true,
    }
  },
  data() {
    return{
      config: {},
      currentScroll: 0,
      searchOptions: '',
      options:[
        {
          option: 'STUN',
          hint: 'STUN server to use. When empty, libsoftphone will make a DNS SRV query for _stun._udp.sipserver and' +
              ' uses the returned address. If no port is specified, 3478 is used.',
          default: '',
          example: 'stun.example.com:[port]',
        },
        {
          option: 'STUNUsername',
          hint: 'Username for STUN/TURN server. If filled in, we’ll consider the STUN server TURN-capable.',
          default: '',
          example: 'user',
        },
        {
          option: 'STUNPassword',
          hint: 'Password for STUN/TURN server.',
          default: '',
          example: 'password',
        },
        {
          option: 'natTraversal',
          hint: 'Specifies the NAT traversal mode to use. This setting controls which addresses will be put into SDP. The options are described in detail below:\n' +
              '\n' +
              'off:\twill always send local/private IP address detected from local network interface. No public address resolution is done.\n' +
              'auto:\twill do a STUN query for a public address. If symmetric NAT is not detected, the global address is used. In case of symmetric NAT, TURN server is used if STUNUsername is filled in, otherwise private/local address is used.\n' +
              'stun:\twill do a STUN query and always use the obtained public address (unless symmetric NAT is detected or if ignoreSymmetricNat is set to 1)\n' +
              'turnAlways:\twill always use TURN server\n' +
              'ice:\twill use the ICE process to choose the best address',
          default: 'auto',
          example: 'password',
          validValues: ['off', 'auto', 'stun', 'turnAlways', 'ice']
        },
        {
          option: 'ignoreSymmetricNat',
          hint: 'if set, it will not check for symmetric NAT and will use the public IP if instructed so even when on symmetric NAT. Setting it to 1 is not recommended, since the IP:port detected will most likely not be accessible from outside, but sometimes it may be necessary to send the public IP even though the port is wrong.',
          default: '0',
          example: '1',
          validValues: ['0', '1']
        },
        {
          option: 'iceDefaultCandidateOrder',
          hint: 'You can prioritise one default ICE candidate over others.\n' +
              '\n' +
              'For example to prefer server reflexive address over relay address, set it to srflx,host,relay. ' +
              'To prefer host address, set it to host,srflx,relay.',
          default: 'relay,srflx,host',
          example: 'relay,srflx,host',
          validValues: ['comma-separated list of "relay,srflx,host"']
        },
        {
          option: 'contactIP',
          hint: 'Specifies which address to use in Contact and Via SIP headers:\n' +
              '\n' +
              'internal:\twill use the local/private in the Contact and Via SIP header.\n' +
              'external:\twill try to determine the public address using STUN ping, OPTIONS packet or STUN server and ' +
              'use it in Contact and Via header.\n' +
              'static:\twill use a static address in Contact and Via header, the address is specified in forcedContact node.',
          default: 'internal',
          example: 'relay,srflx,host',
          validValues: ['internal', 'external', 'static']
        },
        {
          option: 'forcedContact',
          hint: 'This IP address will be used in Contact and Via headers in case contactIP is set to “static”.' +
              '\n' +
              'internal:\twill use the local/private in the Contact and Via SIP header.\n' +
              'external:\twill try to determine the public address using STUN ping, OPTIONS packet or STUN server and ' +
              'use it in Contact and Via header.\n' +
              'static:\twill use a static address in Contact and Via header, the address is specified in forcedContact node.',
          default: 'empty string',
          example: '192.168.100.100:7777',
          validValues: []
        },
        {
          option: 'sendAudioBack',
          hint: 'If set to 1, we will always send the audio back to the address from which we receive the audio from ' +
              'the other side.',
          default: '1',
          example: '1',
          validValues: ['0', '1']
        },
        {
          option: 'keepAlive',
          hint: 'When set, libSoftphone periodically sends keep-alive packet to SIP server, to keep the connection ' +
              'active and NAT port mappings open.',
          default: '0',
          example: '1',
          validValues: ['0', '1']
        },
        {
          option: 'keepAlivePeriod',
          hint: 'Specifies the amount of seconds between two keep-alive packets',
          default: '30',
          example: '5',
          validValues: ['Anything greater than or equal to 5']
        },
        {
          option: 'rtpPortRangeStart',
          hint: 'The port range to use for RTP sockets.',
          default: '10000',
          example: '10000',
          validValues: ['Greater than or equal to 1025']
        },
        {
          option: 'rtpPortRangeEnd',
          hint: 'The port range to use for RTP sockets.',
          default: '65535',
          example: '10000',
          validValues: ['Less than or equal to 65535']
        },
        {
          option: 'listeningPort',
          hint: 'The local port which the SIP stack will bind to: \n\n If left empty, the SIP stack will choose a ' +
              'random port number and will bind to it. This number will remain the same for at least one day. If the' +
              ' client is restarted and re-registers repeatedly, it will remember its rinstance, callId and CSeq ' +
              'numbers, port will remain the same and therefore it should always occupy the same registration slot ' +
              'on the server.\n\n' +
              'If set to 0, a random port will be chosen every time the client registers.\n' +
              'If set to a specific numeric value, the SIP stack will always bind to that port.',
          default: 'empty string',
          example: '5060',
          validValues: ['integer numbers between 1025 and 65535 (inclusive)'],
          warning:{
            text: 'On iOS, setting listeningPort for TCP and TLS SIP transports has no effect. Specifying a port to ' +
                'bind to for TCP is not supported by iOS platform.',
          }
        },
        {
          option: 'maxTimeToWaitForWorkingNetworkInMilliseconds',
          hint: 'the time in milliseconds for which the client keeps calls in established state after the network is ' +
              'lost. When this time expires and there is still no network, the call is hung up.',
          default: '15000',
          example: '15000',
          validValues: ['0 - whatever']
        },
      ]
    }
  },
  beforeMount() {
    this.$axios.get('acrobits/get-settings', {params:{domain: this.domain}}).then(res=>{
      this.config = Object.assign({}, res.data)
    })
  },
  mounted() {
    window.addEventListener('scroll', this.updateScroll)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.window)
  },
  methods:{
    updateScroll() {
      this.currentScroll = window.scrollY
    },
    Save() {
      this.$emit('save-settings', this.config)
    }
  }
}
</script>

<style scoped>

</style>